
import MapUtils from '@/utils/MapUtils.js';

export default class EmployeeEvents {
  
  constructor(eventsDomain) {
    this.eventsDomain = eventsDomain;
    this.domain = this.eventsDomain.domain;
  }
  
  contains(name) {
    if (name) {
      //
    }
    return false;
  }
  
  list(filter) {
    var event = EmployeeEvents.List.Request(filter);
    return this.eventsDomain.initRequest(event);
  }
  
  restore(employee) {
    var event = EmployeeEvents.Restore.Request(employee);
    return this.eventsDomain.initRequest(event);
  }
 
  save(employeeMap) {
    var event = EmployeeEvents.Save.Request(employeeMap);
    return this.eventsDomain.initRequest(event);
  }
  
  static Save = {
      Names: {
        Request: "EmployeeSaveRequestEvent",
        Response: "EmployeeSaveResponseEvent",
      },
      Errors: {
        
      },
      Request: function(employeeMap) {
        var event = {
            handler: EmployeeEvents.Save.Names.Request,
            eventName: EmployeeEvents.Save.Names.Request,
            EmployeeMap: employeeMap.data,
        }
        return event;
      }
    }
  
  static Add = {
    Names: {
      Request: "EmployeeAddRequestEvent",
      Response: "EmployeeAddResponseEvent",
    },
    Errors: {
      
    },
    Request: function(kvps) {
      var event = {
          handler: "EmployeeAddRequestEvent",
          eventName: "EmployeeAddRequestEvent",
      }
      event = MapUtils.updateMap(kvps, event);
      return event;
    }
  }
  
  static Resend = {
    Names: {
      Request: "EmployeeReinviteRequestEvent",
      Response: "EmployeeReinviteResponseEvent",
    },
    Errors: {
      
    },
    Request: function(company, user) {
      var event = {
          handler: EmployeeEvents.Resend.Names.Request,
          eventName: EmployeeEvents.Resend.Names.Request,
          User: user.id(),
          Company: company.id(),
      }
      return event;
    }
  }
  
  static Remove = {
    Names: {
      Request: "EmployeeRemoveRequestEvent",
      Response: "EmployeeRemoveResponseEvent",
    },
    Errors: {
      
    },
    Request: function(userToRemove) {
      var event = {
          handler: EmployeeEvents.Remove.Names.Request,
          eventName: EmployeeEvents.Remove.Names.Request,
      }
      if (userToRemove.data) {
        event["removeEmployee"] = userToRemove.data;
      } else {
        event["removeEmployee"] = userToRemove;
      }
      return event;
    }
  }
  
  static Restore = {
      Names: {
        Request: "EmployeeRestoreRequestEvent",
        Response: "EmployeeRestoreResponseEvent",
      },
      Errors: {
        
      },
      Request: function(employee) {
        var event = {
            handler: EmployeeEvents.Restore.Names.Request,
            eventName: EmployeeEvents.Restore.Names.Request,
        }
        if (employee.data) {
          event['restoreEmployee'] = employee.data;
        } else {
          event['restoreEmployee'] = employee;
        }
        return event;
      }
    }
  
  static List = {
    Names: {
      Response: 'EmployeeListResponseEvent',
      Request: 'EmployeeListRequestEvent',
    },

    Errors: {
      NotFound: {
        id: 'NotFound',
        msg: 'NotFound',
        details: ''
      },
    },

    Request: function(filter) {
      const event = {
          handler: EmployeeEvents.List.Names.Request,
          eventName: EmployeeEvents.List.Names.Request,
          filter: filter.data,
      };
      return event;
    },
  }

}
